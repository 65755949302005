import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./Components/Dashboard";
import Login from "./Components/Login";
import Protected from "./Protected";
import CheckUuid from "./Components/CheckUuid";
import { QueryClientProvider, QueryClient } from "react-query";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App bg-slate-100">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(e) => {
              console.log(e);
            }}
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/check/:uuid" element={<CheckUuid />} />

              <Route element={<Protected />}>
                <Route element={<Dashboard />} path="/dashboard" />
              </Route>
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
