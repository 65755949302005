import {
  Container,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Img from "../assests/LogoImg.jpg";
import logo from "../assests/logo.png";
import Loader from "./loader/loader";
import { useErrorBoundary } from "react-error-boundary";

const Login = () => {
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();
  const [values, setValues] = useState({
    email: "",
    pass: "",
    showPass: false,
  });

  const [status, setStatus] = useState({ isLoading: false, error: false });

  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus({
      isLoading: true,
      error: false,
    });
    axios
      .post("https://api.foody.gomaplus.tech/api/login", {
        email: values.email,
        password: values.pass,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("branch_id", res.data.user.branch.id);
        if (res.status === 200) {
          navigate("/dashboard");
        }
        setStatus({
          isLoading: false,
          error: false,
        });
        console.log(res.status);
      })
      .catch((err) => {
        if (err?.response?.status === 422) {
          setStatus({
            isLoading: false,
            error: true,
          });
        } else {
          showBoundary(err);
        }

        console.error(err);
      });
  };
  const handlePassVisibilty = () => {
    setValues({
      ...values,
      showPass: !values.showPass,
    });
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1">
      <div className="lg:w-full w-10/12 mx-auto">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <img
              src={logo}
              alt=""
              className=" w-20 sm:w-24 md:w-32 lg:w-40 mx-auto py-10"
            />
            <h1 className="pb-20 flex text-lg md:text-xl lg:text-2xl text-gray-600 mx-auto tracking-wide font-semibold">
              Welcome to Cashier
            </h1>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <TextField
                    type="email"
                    fullWidth
                    label="Enter your email"
                    placeholder="Email Address"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                </Grid>

                <Grid item>
                  <TextField
                    type={values.showPass ? "text" : "password"}
                    fullWidth
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, pass: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePassVisibilty}
                            aria-label="toggle password"
                            edge="end"
                          >
                            {values.showPass ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  {status.isLoading ? (
                    <Box sx={{ transform: "scale(0.5)" }}>
                      <Loader />
                    </Box>
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundImage: `linear-gradient(to right, #d22afc, #a758ed,#7073de)`,
                        fontWeight: "bold",
                        width: "50%",
                        padding: "8px",
                      }}
                    >
                      Log in
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
            {status.error && (
              <Typography
                textAlign={"left"}
                sx={{ color: "red", fontWeight: "bold" }}
              >
                wrong credentials
              </Typography>
            )}
          </Grid>
        </Container>
      </div>
      <div className=" hidden  lg:flex items-center justify-center relative">
        <img
          src={Img}
          alt=""
          className="max-h-screen w-full object-cover  object-center opacity-80 "
        />
        <img src={logo} alt="" className="absolute w-52" />
      </div>
    </div>
  );
};

export default Login;
